<template>
  <div class="sm-container">
    <div class="result">
      <!--  成功  -->
      <div v-if="success" class="result-c">
        <img src="@/assets/img/success.png">
        <h3>Confirmed Successful</h3>
<!--        <div class="result-btn">-->
<!--          <router-link :to="`/replacement/${code}`">-->
<!--            Back-->
<!--          </router-link>-->
<!--        </div>-->
      </div>
      <!--  失败  -->
      <div v-else  class="result-c">
        <img src="@/assets/img/fail.png">
        <h3>Sorry, Confirmed Failed</h3>
        <p>{{ errorMessage }}</p>
        <div class="result-btn">
          <router-link to="/mobileCart">
            Back To Shopping Chart
          </router-link>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  name: "mobileResult",
  data() {
    return {
      success: false,
      errorMessage: '',
      code:'',
    };
  },
  created() {
    this.success = this.$route.query.success === 'true';
    this.errorMessage = this.$route.query.errorMessage || '';
    const code = localStorage.getItem('code');
    this.code = code;
  }
}
</script>

<style scoped>
@import "~@/assets/css/mobile.css";
</style>